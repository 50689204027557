import { Link } from 'gatsby'
import styled from 'styled-components'

export const ButtonLinkDefault = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme['neon-pink']};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme['purple-alt']};
    text-decoration: none;
    outline: none;
  }
`

export const ButtonLinkHollow = styled(ButtonLinkDefault)`
  border-color: ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  width: 100%;
  text-align: center;

  @media (min-width: 376px) {
    width: initial;
  }
  @media (min-width: 1024px) {
    width: initial;
  }

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
`

export const ButtonLinkHeader = styled(ButtonLinkDefault)`
  background: ${(props) => props.theme.purple};
  border: none;
  color: #fff;

  @media (min-width: 768px) {
    color: #fff;
    background: none;
    border: 1px solid ${(props) => props.theme['neon-pink']};
  }

  @media (min-width: 1024px) {
    width: auto;
    background: none;
    border: 1px solid ${(props) => props.theme['neon-pink']};
  }
`

export const ButtonLinkDiagonalBlock = styled(ButtonLinkHollow)`
  @media (min-width: 1024px) {
    position: absolute;
    right: 0px;
    bottom: 0px;
    will-change: transform;
  }
`

export const ButtonLinkDarkStretch = styled(ButtonLinkDefault)`
  background: ${(props) => props.theme.purple};
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
`

// narrowStretch
// darkStretch
