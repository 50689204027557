import React from "react"
import axios from "axios-jsonp-pro"
import isEmail from "validator/lib/isEmail"
import Img from "./responsiveCloudinary"
import FloatingLabel from "./floatingLabel"
import styles from "./contactForm.module.scss"
import { ButtonLinkDarkStretch, ButtonLinkDefault } from "./buttonStyles"
import pin from "../assets/images/pin.svg"
import close from "../assets/images/close.svg"
import phone from "../assets/images/phone.svg"
import email from "../assets/images/email.svg"
import styled from "styled-components"
import { GraphQLClient } from "graphql-request"

const form = {
  fullName: "",
  company: "",
  email: "",
  message: "",
}

const MY_MUTATION = `
mutation ($fullName: String, $email: String, $company: String, $message: String) {
  createContactEnquiry(
    input: {clientMutationId: "Contact Form Enquiry", fullName: $fullName, email: $email,  company: $company, message: $message, }
  ) {
    data
    success
    __typename
  }
}
`

const FormButton = styled(ButtonLinkDarkStretch)`
  position: relative;
  top: -3px;

  &:hover {
    background: #84004b;
  }
`

const rules = {
  message: (val) => {
    return val.length < 10 ? "Please enter a longer message" : false
  },
  email: (val) => {
    return !isEmail(val) ? "Please enter a valid email" : false
  },
}

class ContactForm extends React.Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      visibility: null,
      formState: styles.fresh,
      form,
      errors: {},
    }
  }

  componentDidMount() {
    this._isMounted = true

    document.addEventListener("controlContactForm", (evt) => {
      if (!this._isMounted) {
        return false
      }

      this.setState({
        visibility:
          this.state.visibility === styles.visible ? null : styles.visible,
      })

      // iOS Modal Input Fix
      // https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
      if (this.state.visibility) {
        document.body.removeAttribute("style")
      } else {
        setTimeout(function () {
          document.body.style.overflow = "hidden"
          document.body.style.height = "100%"
          document.body.style.width = "100%"
          document.body.style.position = "fixed"
        }, 750)
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleClick() {
    if (typeof CustomEvent === "function") {
      let evt = new CustomEvent("controlContactForm")
      document.dispatchEvent(evt)

      if (typeof window !== "undefined") {
        window.history.pushState(
          "",
          document.title,
          window.location.pathname + window.location.search
        )
      }

      //reset to step1 after animation completes
      setTimeout(() => {
        this.setState({ formState: styles.fresh })
      }, 1000)
    }
  }

  validateInputs() {
    const rule = Object.entries(rules)
    const { form } = this.state
    let errors = {}

    rule.forEach((rule) => {
      let value = form[rule[0]]
      let result = rule[1](value)

      if (result) {
        let newError = {}
        newError[rule[0]] = result
        errors = { ...errors, ...newError }
      }
    })

    this.setState({ errors })
    return Object.keys(errors).length
  }

  handleSubscribe(e) {
    this.setState({
      form: { ...this.state.form, subscribe: e.target.checked },
    })
  }

  async handleSubmit(e) {
    e.preventDefault()

    if (this.validateInputs()) {
      //there's errors, dont post form
      return
    }

    this.setState({ formState: styles.loading })

    const endpoint = process.env.GATSBY_GRAPHQL_ENDPOINT

    const client = new GraphQLClient(endpoint, { headers: {} })

    const variables = this.state.form

    try {
      this.setState({ formState: styles.submitted, form })
      this.refs.formInner.scrollTop = 0
      const data = await client.request(MY_MUTATION, variables)

      // Handle success
    } catch (error) {
      console.error(error)
      // Handle error
    }

    // //send enquiry
    // axios
    //   .post(
    //     'https://9jsqtxtw3a.execute-api.ap-southeast-2.amazonaws.com/dev/avenue/contact',
    //     { ...this.state.form }
    //   )
    //   .then((data) => {
    //     this.setState({ formState: styles.submitted, form })
    //     this.refs.formInner.scrollTop = 0
    //   })
    //   .catch((error) => {
    //     console.error(error, 'error')
    //   })

    //subscribe

    if (this.state.form.subscribe) {
      axios
        .jsonp(
          "https://design.us18.list-manage.com/subscribe/post?u=682264e90baafdaff1e6fb432&id=6e5d9615d3&c=?",
          {
            params: {
              EMAIL: this.state.form.email,
              FNAME: this.state.form.fullName,
            },
          }
        )
        .catch((error) => {
          console.error(error, "error")
        })
    }
  }

  handleInput(name, event) {
    const form = { ...this.state.form }
    form[name] = event.target.value
    this.setState({ form })
  }

  render() {
    return (
      <section
        ref="formInner"
        className={`${styles.block} ${this.state.visibility}`}
      >
        {/* <div className={styles.columnWrap}> */}

        <div className={styles.formWrap}>
          <div className={`${styles.form} ${this.state.formState}`}>
            <div className={styles.formInner}>
              <img
                onClick={this.handleClick.bind(this)}
                className={styles.close}
                src={close}
                alt="close"
              />

              {/* Thankyou Message */}
              <div className={styles.step2}>
                <h2>Thanks!</h2>
                <p className={styles.thanksMsg}>
                  We got your message and will be in touch as soon as possible.
                </p>
                <FormButton as="button" onClick={this.handleClick.bind(this)}>
                  Take me back
                </FormButton>
              </div>
              {/* Contact Form */}
              <div className={styles.step1}>
                <h3>Nice to meet you!</h3>
                <p>We'd love to hear more about you and your ideas. </p>
                <form>
                  <ul className={styles.formSkeleton}>
                    <li>
                      <FloatingLabel
                        placeholder="Name"
                        className={styles.input}
                        type="text"
                        name="fullName"
                        id="name"
                        value={this.state.form.fullName}
                        onChange={this.handleInput.bind(this, "fullName")}
                      />
                    </li>
                    <li>
                      <FloatingLabel
                        placeholder="Company"
                        className={styles.input}
                        type="text"
                        name="company"
                        id="company"
                        value={this.state.form.company}
                        onChange={this.handleInput.bind(this, "company")}
                      />
                    </li>
                    <li>
                      <FloatingLabel
                        placeholder="Email"
                        className={`${styles.input} ${
                          this.state.errors.email && styles.hasError
                        }`}
                        type="email"
                        name="email"
                        id="emailmodal"
                        value={this.state.form.email}
                        onChange={this.handleInput.bind(this, "email")}
                        error={this.state.errors.email}
                      />
                    </li>
                    <li className={styles.multilineWrap}>
                      <FloatingLabel
                        placeholder="Message"
                        className={`${styles.multiline} ${
                          this.state.errors.message && styles.hasError
                        }`}
                        type="textarea"
                        name="message"
                        id="message"
                        value={this.state.form.message}
                        onChange={this.handleInput.bind(this, "message")}
                        error={this.state.errors.message}
                      />
                    </li>
                    <li className={styles.submitRow}>
                      <div>
                        <input
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          onChange={this.handleSubscribe.bind(this)}
                          value={this.state.form.subscribe}
                        />
                        <label htmlFor="subscribe">
                          <span>
                            <span className={styles.purple}>Subscribe</span> to
                            get our latest news, insights and inspiration.
                          </span>
                        </label>
                      </div>
                      <div>
                        <FormButton
                          as="button"
                          onClick={this.handleSubmit.bind(this)}
                        >
                          Send it!
                        </FormButton>
                      </div>
                    </li>
                  </ul>
                </form>
                <footer className={styles.formFooter}>
                  <div className={styles.newBiz}>
                    For new business enquiries,
                    <br />
                    please contact <strong>Brenton Cannizzaro</strong>
                  </div>
                  <div className={styles.contact}>
                    <a href="mailto:brenton@avenue.studio">
                      <img src={email} alt="" /> Email Brenton
                    </a>
                    <a href="tel:+61448833157" rel="tel">
                      <img src={phone} alt="" /> Call +61 448 833 157
                    </a>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.img}>
          <a
            href="https://goo.gl/maps/5tFkP6mL6xB2"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.addressCard}
          >
            <h3>Pay us a visit</h3>
            <p>
              231 Harbour Esplanade
              <br />
              Docklands VIC 3008
            </p>
            <img className={styles.pin} src={pin} alt="" />
          </a>
          <Img
            className={styles.bg}
            user="avenue"
            publicId="HP/contact-hero_2x"
            alt=""
          />
        </div>
        {/* </div> */}
      </section>
    )
  }
}

export const ToggleContactForm = ({ children, className, onClick }) => {
  let handleClick = (e) => {
    if (typeof CustomEvent === "function") {
      let evt = new CustomEvent("controlContactForm")
      document.dispatchEvent(evt)

      if (onClick) {
        onClick()
      }
    }

    return false
  }

  const getButton = (className) => {
    return (
      <ButtonLinkDefault as="button" onClick={handleClick.bind(this)}>
        {children}
      </ButtonLinkDefault>
    )
  }

  return getButton(className)
}

export default ContactForm
