import React from "react"
import { Link } from "gatsby"

import ContactForm from "../components/contactForm"

import logo from "../assets/images/avenue-logo-full.svg"
import alogo from "../assets/images/a-logo.svg"
import facebook from "../assets/images/facebook-white.svg"
import twitter from "../assets/images/twitter-white.svg"
import linkedin from "../assets/images/linkedin-white.svg"
import instagram from "../assets/images/instagram-white.svg"
import ig from "../assets/images/ig-white.svg"

import styles from "./siteHeader.module.scss"
import styled from "styled-components"
import { media } from "../utils/style-utils"

const HeaderInner = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  height: 80px;
  display: flex;
  align-items: center;

  ${media.medium`
    height:120px;
    justify-content: space-between;
  `};

  ${media.xxlarge`
    max-width: 1480px;
    height: 160px;
  `};

  @media (min-width: 1024px) and (max-height: 900px) {
    height: 100px;
  }
`

const Logo = styled.img`
  width: 124px;

  ${media.large`
    width: auto;
    opacity: 0;
    transition: opacity 500ms;
    opacity: ${(props) => (props.logoDesktopIntroAnimate && 1) || 0};
  `};
`

const NavLink = styled(Link)`
  color: ${(props) => props.theme.steel};
  letter-spacing: 1px;
  font-size: 1.5rem;
  margin: 0 27px;
  font-weight: 500;
  transition: opacity 500ms ease-in;

  &:active,
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.purple};
  }

  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.steel};
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 1025px) {
    color: #fff;
    display: block;
    text-align: center;
    font-size: 2rem;
    line-height: 7.2rem;
    margin: 0;
    opacity: 0;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }
`

const Menu = styled.nav`
  a {
    transition: opacity 500ms ease-in;
    opacity: ${(props) => props.menuOpen && 1};

    ${media.large`
        opacity: ${(props) => (props.menuDesktopIntroAnimate && 1) || 0};
    `};

    &:nth-of-type(2) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "600ms"};
    }

    &:nth-of-type(3) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "700ms"};
    }
    &:nth-of-type(4) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "800ms"};
    }
    &:nth-of-type(5) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "900ms"};
    }
    &:nth-of-type(6) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "1000ms"};
    }
    &:nth-of-type(7) {
      transition-delay: ${((props) =>
        props.menuOpen || props.menuDesktopIntroAnimate) && "1100ms"};
    }
  }
`

export default class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      logoDesktopIntroAnimate: false,
    }
  }

  componentDidMount() {
    this.setState({
      logoDesktopIntroAnimate: true,
    })
  }

  render() {
    return (
      <header>
        <HeaderInner>
          <Link to="/">
            <Logo
              logoDesktopIntroAnimate={this.state.logoDesktopIntroAnimate}
              src={logo}
              alt="Home"
            />
          </Link>
          <Navigation />
        </HeaderInner>
        <ContactForm />
      </header>
    )
  }
}

class Navigation extends React.Component {
  constructor() {
    super()
    this.state = {
      navClass: null,
      menuOpen: false,
      menuDesktopIntroAnimate: false,
    }
  }

  componentDidMount() {
    this.setState({
      menuDesktopIntroAnimate: true,
    })
  }

  toggleNav(e, openContact) {
    this.setState({ menuOpen: !this.state.menuOpen })

    //if mobile then hide body overflow, else ensure overflow not hidden
    if (window && window.innerWidth < 1025) {
      document.querySelector("body").style.overflow = !this.state.navClass
        ? "hidden"
        : "initial"
      this.setState({
        navClass: this.state.navClass !== styles.open ? styles.open : null,
      })
    } else {
      document.querySelector("body").style.overflow = "initial"
      this.setState({ navClass: null })
    }

    if (openContact) {
      e.preventDefault()

      if (typeof CustomEvent === "function") {
        let evt = new CustomEvent("controlContactForm")
        document.dispatchEvent(evt)
      }
    }
  }

  render() {
    return (
      <div className={this.state.navClass}>
        <button
          className={`${
            styles.toggleNavigation
          } hamburger hamburger--collapse ${
            this.state.navClass && "is-active"
          }`}
          onClick={this.toggleNav.bind(this)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        {/* <Menu /> */}
        <Menu
          menuOpen={this.state.menuOpen}
          menuDesktopIntroAnimate={this.state.menuDesktopIntroAnimate}
          className={styles.nav}
        >
          <span onClick={this.toggleNav.bind(this)}>
            <img className={styles.alogo} src={alogo} alt="Avenue" />
          </span>
          <NavLink to="/work" onClick={this.toggleNav.bind(this)}>
            Work
          </NavLink>
          <NavLink to="/what-we-do" onClick={this.toggleNav.bind(this)}>
            What we do
          </NavLink>
          <NavLink to="/who-we-work-with" onClick={this.toggleNav.bind(this)}>
            Who we work with
          </NavLink>
          <NavLink to="/blog" onClick={this.toggleNav.bind(this)}>
            Insights
          </NavLink>
          <NavLink to="/about" onClick={this.toggleNav.bind(this)}>
            About
          </NavLink>
          {/* <NavLink to="/inspiration" onClick={this.toggleNav.bind(this)}>
            Inspiration
          </NavLink> */}
          <NavLink to="#contact" onClick={(e) => this.toggleNav(e, true)}>
            Contact
          </NavLink>
          {/* <a className={styles.link} href="https://medium.com/bookcase" onClick={this.toggleNav.bind(this)}>Blog</a> */}

          <div className={styles.social}>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/avenuedesign"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/madebyavenue"
            >
              <img src={twitter} alt="twitter" />
            </a>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/madebyavenue/"
            >
              <img src={instagram} alt="instagram" />
            </a>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/avenue"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
            <a
              className={styles.socialLink}
              target="_blank"
              rel="noopener noreferrer"
              href="http://theinspirationgrid.com/"
            >
              <img src={ig} alt="inspiration grid" />
            </a>
          </div>
        </Menu>
      </div>
    )
  }
}
