const postData = `
query($id: ID!) {
  post(id:  $id ) {
    title
    uri
    excerpt
    content
    date
    tags {
      nodes {
        name
      }
    }
    headerSettings {
      headerImage {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    avBlog {
      type
      roundupItems {
        heading
        description
        link
        linkText
        image {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
    seo {
      metaDesc
      title
      opengraphImage {
        sourceUrl
      }
    }
  }
}
`
const workData = `
query($id: ID!) {
  post: work(id:  $id ) {
    title
      uri
      content
      date
      headerSettings {
        headerImage {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
      avWork {
        intro {
          content
          image {
            sourceUrl
            srcSet
          }
          imageVariation
        }
        ctaStrip {
          heading
          buttonText
        }
        relatedContent {
          heading
          link
          image {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
      }
     
      seo {
        metaDesc
        title
        opengraphImage {
          sourceUrl
        }
      }
    }
}
`
const whoData = `
query($id: ID!) {
   post: who(id: $id) {
    title
    uri
    content
    date
    headerSettings {
      headerImage {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    avWho {
      intro {
        content
        image {
          sourceUrl
          srcSet
        }
        imageVariation
      }
      ctaStrip {
        heading
        buttonText
      }
      relatedContent {
        heading
        link
        image {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
    seo {
      metaDesc
      title
      opengraphImage {
        sourceUrl
      }
    }
  }
}
`
const whatData = `
query ($id: ID!) {
  post: what(id: $id) {
    title
    uri
    content
    date
    headerSettings {
      headerImage {
        sourceUrl
        srcSet
        mediaDetails {
          height
          width
        }
      }
    }
    avWhat {
      intro {
        content
        image {
          sourceUrl
          srcSet
        }
        imageVariation
      }
      ctaStrip {
        heading
        buttonText
      }
      relatedContent {
        heading
        link
        image {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }
    }
    seo {
      metaDesc
      title
      opengraphImage {
        sourceUrl
      }
    }
  }
}

`

module.exports = {
  postData,
  workData,
  whoData,
  whatData,
}
