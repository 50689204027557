// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-old-js": () => import("./../../../src/pages/about-old.js" /* webpackChunkName: "component---src-pages-about-old-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-what-we-do-old-js": () => import("./../../../src/pages/what-we-do-old.js" /* webpackChunkName: "component---src-pages-what-we-do-old-js" */),
  "component---src-pages-who-we-work-with-js": () => import("./../../../src/pages/who-we-work-with.js" /* webpackChunkName: "component---src-pages-who-we-work-with-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single/post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-what-js": () => import("./../../../src/templates/single/what.js" /* webpackChunkName: "component---src-templates-single-what-js" */),
  "component---src-templates-single-who-js": () => import("./../../../src/templates/single/who.js" /* webpackChunkName: "component---src-templates-single-who-js" */),
  "component---src-templates-single-work-js": () => import("./../../../src/templates/single/work.js" /* webpackChunkName: "component---src-templates-single-work-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

