import React from 'react'

import cfstyles from './contactForm.module.scss'

export default (props) => {
  let { error, placeholder, ...propsFormAttr } = props

  return (
    <div className="input-group">
      <div className="has-float-label">
        {props.type !== 'textarea' && (
          <input id={props.name} placeholder=" " {...propsFormAttr} />
        )}
        {props.type === 'textarea' && (
          <textarea id={props.id} placeholder=" " {...propsFormAttr} />
        )}
        <label htmlFor={props.id}>{placeholder}</label>
      </div>

      {props.error && <div className={cfstyles.error}>{props.error}</div>}
    </div>
  )
}
