const {
  postData,
  workData,
  whatData,
  whoData,
} = require("../src/preview/previewData")

// Page Templates
const templateMap = {}

// Post Types
const postTypeMap = {
  post: {
    previewData: postData,
  },
  work: {
    previewData: workData,
  },
  what: {
    previewData: whatData,
  },
  who: {
    previewData: whoData,
  },
}

module.exports = { templateMap, postTypeMap }
