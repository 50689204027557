import React from 'react'
import styles from './responsiveCloudinary.module.scss'
import PropTypes from 'prop-types'

const BREAKPOINTS = {
  small: 480,
  medium: 768,
  large: 1024,
  big: 1280,
  xlarge: 1440,
}

class ResponsiveCloudinary extends React.Component {
  static contextTypes = {
    parallaxController: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      imageLoaded:
        this.props.fadeInOnLoad || this.props.scaleInOnLoad ? false : true,
    }

    this.basePath = `//res.cloudinary.com/${this.props.user}/image/upload/`
    this.handleSrcSet = this.handleSrcSet.bind(this)

    this.handleSizes()
  }

  componentDidMount() {
    if (this.props.scaleInOnLoad) {
      setTimeout(() => {
        this.imageLoaded()
      }, 100)
    }
  }

  imageLoaded() {
    this.setState({
      imageLoaded: true,
    })

    if (this.context.parallaxController) {
      setTimeout(() => {
        this.context.parallaxController.update()
      }, 500)
    }
  }

  handleSrcSet() {
    let srcSet = ''
    for (let size of this.props.sourceSizes) {
      srcSet = `${srcSet}${this.basePath}f_auto,q_auto,w_${size}/${this.props.publicId} ${size}w, `
    }

    if (this.props.gif) {
      return null
    } else {
      return srcSet
    }
  }

  handleSizes() {
    let { small, medium, large, big, xlarge } = this.props
    let sizes = { small, medium, large, big, xlarge }
    let mediaquery = ''

    Object.keys(sizes)
      .reverse()
      .forEach((val, idx) => {
        if (sizes[val]) {
          let append = `(min-width: ${BREAKPOINTS[val]}px) ${sizes[val]},`
          mediaquery += append
        }
      })

    if (this.props.gif) {
      return null
    } else {
      return mediaquery + ' 100vw'
    }
  }

  render() {
    let oStyles = {}

    if (this.props.fadeInOnLoad) {
      oStyles = {
        opacity: this.state.imageLoaded ? 1 : 0,
        transition: 'opacity 500ms ease-in-out',
      }
    }

    if (this.props.scaleInOnLoad) {
      oStyles = {
        opacity: this.state.imageLoaded ? 1 : 0,
        transform: this.state.imageLoaded ? 'scale(1)' : 'scale(1.2)',
        transition: 'opacity 500ms ease-in-out, transform 700ms ease-in-out',
      }
    }

    return (
      <img
        style={oStyles}
        onLoad={this.imageLoaded.bind(this)}
        className={`${styles.block} ${this.props.className}`}
        sizes={this.handleSizes()}
        srcSet={this.handleSrcSet()}
        src={`${this.basePath}f_auto/${this.props.publicId}`}
        alt={this.props.alt}
        loading={this.props.loading}
      />
    )
  }
}

ResponsiveCloudinary.defaultProps = {
  quality: 70,
  loading: 'auto',
  sourceSizes: [512, 768, 1024, 1400, 1600, 2180, 2500, 3000],
}

export default ResponsiveCloudinary
