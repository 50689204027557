import React from 'react'
import Helmet from 'react-helmet'

import '../assets/styles/main.scss'
import '../assets/FoundersFont/stylesheet.css'

import SiteHeader from './siteHeader'
import { graphql, StaticQuery } from 'gatsby'

if (
  typeof navigator !== 'undefined' &&
  navigator.userAgent.toLowerCase().indexOf('firefox') === -1
) {
  document.body.classList.add('bgAdjust')
}

const Layout = ({ children }) => (
  <main>
    <StaticQuery
      query={graphql`
        query SiteMetadata {
          site {
            siteMetadata {
              siteUrl
              title
              description
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: { title, description },
        },
      }) => (
        <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`}>
          <meta name="Description" content={description} />
          <meta property="og:site_name" content="avenue.design" />
          <meta
            property="og:image"
            content="http://res.cloudinary.com/avenue/image/upload/v1522059742/avenue-og-image-02_rotsxd.png"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Brave companies stand out from the pack"
          />
          <meta
            property="og:description"
            content="We pair design and technology to take you where others won't."
          />
          <meta property="og:url" content="https://avenue.design" />
          <meta property="fb:app_id" content="195036151103189" />
          <meta
            name="twitter:description"
            content="We pair design and technology to take you where others won't."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@madebyavenue" />
          <meta name="twitter:creator" content="@madebyavenue" />
          <meta
            name="twitter:image"
            content="http://res.cloudinary.com/avenue/image/upload/v1522059742/avenue-og-image-02_rotsxd.png"
          />
          <html lang="en" />
        </Helmet>
      )}
    />

    <SiteHeader />
    <div>{children}</div>
  </main>
)

export default Layout
