import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './src/utils/style-utils';
import ParallaxProvider from 'react-scroll-parallax/lib/components/ParallaxProvider';
import Layout from "./src/components/layout"
import PreviewProvider from "./src/context/PreviewContext"
import Preview from "./src/preview/Preview";




export const wrapRootElement = ({ element }) => {

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <PreviewProvider>
        {element}
        </PreviewProvider>
      </ParallaxProvider>
    </ThemeProvider>
  )
}


export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;

  if (!!pageContext.preview) {
    return <Preview pageProps={props} element={element} />;
  } else {
    return <Layout {...props}>{element}</Layout>;
  }
};