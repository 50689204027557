import { css } from 'styled-components'
import styled from 'styled-components'

const sizes = {
  xxxlarge: 1920,
  xxlarge: 1600,
  xlarge: 1440,
  big: 1280,
  large: 1024,
  medium: 768,
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `

  return acc
}, {})

const color = {
  teal: '#54c7d1',
  blue: '#00AEDE',
  'dark-blue': '#038BC6',
  'blue-dot': '#69E1FF',
  'blue-outline': '#54C9E9',
  'nav-background': '#00AFDA',
  'blue-grey': '#F2F6F8',
  'blue-grey-border': '#D5E0E6',
  'dark-grey': '#202020',
  'cool-green': '#08C621',
  steel: '#869599',
  'light-grey': '#DDE1E7',
  'neon-pink': '#F454AF',
  pink: '#EE5D7E',
  purple: '#9D1864',
  'purple-dark': '#711148',
  'grey-bg': '#F2F4F7',
  'text-link': '#BC237D',
  'blue-gradient': 'linear-gradient(120deg, #00AEDE 0%, #0579B3 100%)',
  'transition-length': 'ease-out 0.3s',
  'purple-gradient': 'linear-gradient(48deg, #D11B83 0%, #77194D 100%)',
}

const styles = {
  primaryColor: color.teal,
  primaryFont: '"FoundersGrotesk", sans-serif',
  bodyFont: '"FoundersGrotesk", sans-serif',
  'purple-alt': color.purple,
}

export const theme = { ...color, ...styles }

export const Row = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 1600px) {
    max-width: 1480px;
  }
`
